<!-- 联系我们 -->
<template>
  <div class="contact_us">

    <header>
      <Breadcrumb>
        <BreadcrumbItem to="/">首页</BreadcrumbItem>
        <BreadcrumbItem to="/message">联系我们</BreadcrumbItem>
      </Breadcrumb>
    </header>

    <section>
      <h1>
        联系我们
      </h1>
      <p class="p1">在线提交您的需求/建议</p>

      <Form ref="formValidate" label-position="top" inline :model="formValidate" :rules="ruleValidate">
        <div class="form_item">
          <FormItem label="您的姓名" prop="name" style="flex:1;margin-right:83px">
            <Input size="large" v-model="formValidate.name" placeholder="请输入您的姓名"></Input>
          </FormItem>

          <FormItem label="您的电话/手机" prop="mobile" style="flex:1;">
            <Input size="large" v-model="formValidate.mobile" placeholder="请输入您的手机号码"></Input>
          </FormItem>
        </div>

        <div class="form_item">
          <FormItem label="您的公司名称" prop="enterpriseName" style="flex:1;margin-right:83px">
            <Input size="large" v-model="formValidate.enterpriseName" placeholder="请输入您的公司名称"></Input>
          </FormItem>

          <FormItem label="您的邮箱" prop="email" style="flex:1;">
            <Input size="large" v-model="formValidate.email" placeholder="请输入您的邮箱"></Input>
          </FormItem>
        </div>

        <FormItem label="填写您的需求/建议" prop="suggestion" style="width:100%">
          <Input v-model="formValidate.suggestion" type="textarea" :autosize="{minRows: 5,maxRows: 8}" placeholder="请输入您的需求/建议"></Input>
        </FormItem>

        <FormItem style="margin-top:35px">
          <Button :loading="but_loading" type="error" style='padding: 0 30px;background:#FC4455' @click="handleSubmit('formValidate')">提交</Button>

        </FormItem>
      </Form>

    </section>

    <footer>

    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      but_loading: false,
      formValidate: {

      },
      ruleValidate: {
        name: [
          { required: true, message: '请输入您的姓名', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入您的手机号码', trigger: 'blur' }
        ],
        enterpriseName: [
          { required: true, message: '请输入您的公司名称', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '请输入您的邮箱', trigger: 'blur' },
          { type: 'email', message: '请输入正确邮箱格式', trigger: 'blur' }
        ],
        suggestion: [
          { required: true, message: '填写您的需求/建议', trigger: 'blur' },
        ]
      }
    }
  },
  methods: {
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {

          this.but_loading = true
          this.app("webH5", "saveContactUs", this.formValidate).then(res => {
            this.but_loading = false
            if (res.code == 200) {
              this.$Modal.success({
                title: res.message
              })

            } else {
              this.$Modal.error({
                title: res.message
              })
            }
          })



        } else {
          this.$Message.error('请填写完整信息');
        }
      })
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    }
  },
  created() {

  }
}
</script>

<style lang="less" scoped>
@import "./contact_us.less";
</style>
